import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";

import "./Skills.scss";
import "react-tooltip/dist/react-tooltip.css";

const Skills = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [activeItem, setActiveItem] = useState("0.0");

  useEffect(() => {
    // Order or filter the fetched data
    const query = '*[_type == "experiences"] | order(year desc)';
    const skillsQuery = '*[_type == "skills"] | order(_updatedAt desc)';

    client.fetch(query).then((data) => {
      console.log(data);
      setExperiences(data);
    });
    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills & Experience</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills?.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              // whileHover={{ scale: 1.1 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <motion.div className="app__skills-exp">
          {experiences.map((experience, expId) => (
            <motion.div className="app__skills-exp-item" key={experience.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
                <div className="app__skills-exp-location">
                  <i class="fa-solid fa-location-dot"></i>
                  <p className="p-text">{experience.location}</p>
                </div>
                {/* {console.log(experience.year)} */}
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work, workId) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      whileHover={{ scale: 1.025 }}
                      className={`app__skills-exp-work app__flex ${activeItem === `${expId}.${workId}` ? "open" : "closed"}`}
                      // Had to make changes from tutorial since Tooltip has updated from V4 to V5
                      data-tooltip-id={work.name}
                      data-tooltip-content={work.desc}
                      // data-tooltip-float="true"
                      // data-tooltip-place="top"
                      key={work.name}
                      onClick={() => {setActiveItem(`${expId}.${workId}`); console.log(activeItem)}}
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    {/* <Tooltip
                      id={work.name}
                      // float="true"
                      // effect="solid"
                      // arrowColor="#fff"
                      className="skills-tooltip"
                      // content={work.desc}
                    /> */}
                    <div className={`app__skills-exp-work-desc ${activeItem === `${expId}.${workId}` ? "open" : "closed"}`}>
                      <p>{work.desc}</p>
                    </div>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills & experiences",
  "app__primarybg"
);
